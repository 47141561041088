<splus-forms id="entregaHospitalizacionPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">
  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">

    <splus-forms-header [isLoading]="isLoading">
      <splus-forms-main-title [title]="'Entrega Hospitalización'"
        [titleData]="processType == ProcessType.view ? '' : 'Entrega Hospitalización'" [icon]="icon">
      </splus-forms-main-title>

      <div *ngIf="processType == ProcessType.view || processType == ProcessType.edit">
        <div class="sub-title-info ">
          {{dataAdmisionTocontrol?.nombreCompleto | uppercase }}

          <div *ngIf="isLoading">

            <div class="k-hbox">
              <kendo-skeleton shape="text" animation="pulse" width="100px"></kendo-skeleton> &nbsp;
              <kendo-skeleton shape="text" animation="pulse" width="70px"></kendo-skeleton> &nbsp;
              <kendo-skeleton shape="text" animation="pulse" width="90px"></kendo-skeleton>
            </div>
          </div>


        </div>
      </div>


      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading"
            *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton rounded="small"
            themeColor="primary">
            <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
            rounded="small" themeColor="primary" (click)="goEdit()">
            <i class="fa-solid fa-pencil"></i> Editar
          </button>

          <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small" themeColor="base"
            (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small" themeColor="base"
            (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>


    </splus-forms-header>

    <splus-forms-fields [processType]="processType">
      <splus-forms-group [title]="'Información Admision'" #grupoAdmision
        *ngIf="processType == ProcessType.create || dataAdmisionTocontrol" [mainLayout]="'layout-simple'">
        <div class="main-fields" [ngClass]="{ 'no-width': processType == ProcessType.view }"
          style="grid-template-columns: none; padding: 10px 0" *ngIf="DatosRecord || processType == ProcessType.create">
          <app-search-admissionsV2 #admisiones [filter]="filtersData" [splusFormControl]="f.admision"
            (SendDataEmitter)="GetDataAdmision($event)" [processType]="processType">
          </app-search-admissionsV2>
        </div>
      </splus-forms-group>
      <splus-forms-group [title]="'Información Básica'" #grupoBasico>
        <splus-field *ngVar="f.fecha as ctrl" [splusFormControl]="ctrl" #fecha>

          <kendo-datepicker #calendar splusInput [navigation]="true"  format="dd/MM/yyyy"
            [formControl]="ctrl" [splusGroup]="grupoBasico" [splusField]="fecha" [value]="ctrl.value">

            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
            </ng-template>

          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{calendar.value | SPlusDate}}
          </ng-template>

          <div class="hit">
            {{!calendar.value ? '' : calendar.value | InfoDateFormat}}
          </div>
        </splus-field>

        <splus-field *ngVar="f.hora as ctrl" [splusFormControl]="ctrl">
          <kendo-timepicker splusInput [formControl]="ctrl" [id]="ctrl.nameControl"></kendo-timepicker>
          <ng-template splusFieldViewModeTemplate>
            {{ ctrl?.value | SPlusHour}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.idUsuarioEntrega as ctrl" [splusFormControl]="ctrl" #idUsuarioEntrega>
          <kendo-combobox #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl" [data]="usuariosFilter"
            [clearButton]="select.isOpen" [textField]="'nombre'" [filterable]="true"
            (filterChange)="handleFilter($event)" [valueField]="'id'" [valuePrimitive]="true" [allowCustom]="false">
          </kendo-combobox>

          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.fkBodega as ctrl" [splusFormControl]="ctrl">
          <kendo-combobox #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl" [data]="bodegasFilter"
            [clearButton]="select.isOpen" [textField]="'nombre'" [filterable]="true" (valueChange)="ObtenerLotes($event,f?.idAdmision?.value)"
            (filterChange)="handleFilterBodegas($event)" [valueField]="'id'" [valuePrimitive]="true" [allowCustom]="false">
          </kendo-combobox>

          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>
      </splus-forms-group>

      <splus-forms-group [title]="'Articulos Solicitados'" #Descuentos [mainLayout]="'layout-simple'">
        <div class="mb-1 mt-2">
          <button type="button" themeColor="base" class="p-0 m-0" kendoButton
          [disabled]="processType == ProcessType.view || !dataGridMedicamentos || dataGridMedicamentos?.length == 0" (click)="openedAgregarSuministro()">

          <i class="fa-solid fa-plus  text-primary dark:text-blue-500 mr-1"></i>
          <span class="text-normal dark:text-white">
            Agregar Artículo
          </span>
        </button>
          <button type="button" [disabled]="mySelection.length == 0" themeColor="base" class="p-0 m-0 ml-2"
            kendoButton (click)="dialogEliminarConceptosAplicadosSelecionados()">
            <i class="fa-solid fa-trash-can  text-primary dark:text-blue-500 mr-1"></i>
            <span class="text-normal dark:text-white">
              Remover Artículo
            </span>
          </button>


        </div>
        <div>
          <kendo-grid #gridMedicamentos id="gridMedicamentos" (cellClick)="cellClickHandlerMedicamentos($event)"
          [data]="gridDataMedicamentos" [pageSize]="gridStateMedicamentos.take" (remove)="removeHandlerMedicamento($event)"
          [skip]="gridStateMedicamentos.skip" [navigable]="true" (cancel)="cancelHandlerMedicamentos($event)"
          [pageable]="true" (pageChange)="pageChangeMedicamentos($event)" [selectable]="true" [kendoGridSelectBy]="mySelectionKey" [selectable]="{checkboxOnly: true}"
          [(selectedKeys)]="mySelection">

          <kendo-grid-checkbox-column  [showSelectAll]="true"
          width="40"></kendo-grid-checkbox-column>
          <kendo-grid-column field="articulo" [title]="'Artículo'" [editable]="false">

            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.articulo?.nombreCompletoCompuesto}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="loteData" title="Lote" [width]="350">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex">

                  <kendo-dropdownlist #selectCuentas [formControl]="formGroup?.get('loteData')"
                    [clearButton]="false" [filterable]="false"
                    [data]="loteArticuloGrilla" [valueField]="'id'"
                    [textField]="'nombreLote'" [allowCustom]="false" placeholder="Escribe para buscar..."
                    (selectionChange)="ValidarExitencia($event,rowIndex)">
                    <ng-template kendoDropDownListNoDataTemplate>
                      <div>
                        <i class="fa-sharp fa-regular fa-circle-exclamation"></i><br />
                        No hay datos.<br />
                      </div>
                    </ng-template>
                  </kendo-dropdownlist>

            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.loteData?.nombreLote}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Existencias" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.loteData?.existencias}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="cantidad" title="Cantidad" [width]="100">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex">
              <kendo-numerictextbox [spinners]="false" [formControl]="formGroup?.get('cantidad')"
               (keydown.Tab)="saveCurrentMedicamentos()" (keydown.Enter)="saveCurrentMedicamentos()">
              </kendo-numerictextbox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.cantidad}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-command-column title="Acciones" [width]="100" *ngIf="processType !== ProcessType.view">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
              <button kendoGridRemoveCommand iconClass="fa-solid fa-trash-can" fillMode="flat" style="color:#c95242"
                title="Eliminar" [id]="'bt-delete'+rowIndex"></button>
              <button kendoGridCancelCommand iconClass="fa-solid fa-trash-can" fillMode="flat" style="color:#c95242">
              </button>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
        </div>


      </splus-forms-group>
    </splus-forms-fields>

  </form>


</splus-forms>

@if(openedDiagAgregarSuministro)
{
<kendo-dialog [themeColor]="'primary'" title="Agregar Articulos" (close)="closeSuministro()" [minWidth]="450"
  width="90%">
  <div>
    <kendo-grid #gridMedicamentosSolicitud id="gridMedicamentosSolicitud"
          [data]="dataGridMedicamentosSolicitud"
           [navigable]="true"
          [selectable]="true" [kendoGridSelectBy]="'fkArticulo'" [selectable]="{checkboxOnly: true}"
          [(selectedKeys)]="mySelectionSolicitudes" (selectedKeysChange)="selecctionChage($event)">

          <kendo-grid-checkbox-column  [showSelectAll]="true"
          width="40"></kendo-grid-checkbox-column>
          <kendo-grid-column field="articulo" [title]="'Artículo'" [editable]="false">

            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.articulo?.nombreCompletoCompuesto}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="cantidad" title="Cantidad" [width]="100">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex">
              <kendo-numerictextbox [spinners]="false" [formControl]="formGroup?.get('cantidad')"
               (keydown.Tab)="saveCurrentMedicamentos()" (keydown.Enter)="saveCurrentMedicamentos()">
              </kendo-numerictextbox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.cantidad}}
            </ng-template>
          </kendo-grid-column>

        </kendo-grid>
  </div>

  <kendo-dialog-actions>
    <div class="flex flex-row justify-between items-center">
      <div>
        <button kendoButton iconClass="fa-sharp fa-regular fa-check-to-slot" (click)="toggleSelectAll()">Seleccionar
          Todos</button>
      </div>
      <div>
        <button kendoButton (click)="closeSuministro() " class="mr-2">Cancelar</button>
        <button kendoButton (click)="callLoadSelect()" [disabled]="mySelectionSolicitudes.length == 0" themeColor="primary" iconClass="fa-solid fa-check">
          Agregar Seleccionados
        </button>
      </div>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
}
<ng-container #container *ngIf="!closePop"></ng-container>

import { Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdmisionesDTO } from '@src/app/models/General/AdmisionesDTO';
import { CaracteristicasModule } from '@src/app/models/General/Permisos';
import { LotesArticulosDTO, LotesDTO, MovimientoInventarioDTO, MovimientoInventarioDocumentosDTO } from '@src/app/models/Inventario/EntregaHospitalizacion/MovimientoInventarioDTO';
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { FiltersData } from '@src/app/models/base/filterModel';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { EntregaHospitalizacionService } from '../services/entrega-hospitalizacion.service';
import { EntregaHospitalizacionSettings } from '../config/entrega-hospitalizacion.config';
import { SearchAdmissionsV2Service } from '@src/app/Components/Generales/Admisiones/search-admissionsV2/services/search-admissionsV2.service';
import { ProcessType, SPlusDialogCloseResult, SPlusDialogSettings, SPlusFormsDialogsService, SPlusMoreOption, SPlusOption } from '@saludplus/forms';
import { CancelEvent, CellClickEvent, GridComponent, GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { DefaultModelDTO } from '@src/app/models/General/DefaultModelDTO';
import format from 'date-fns/format';

@Component({
  selector: 'app-entrega-hospitalizacion',
  templateUrl: './entrega-hospitalizacion.component.html',
  styleUrls: ['./entrega-hospitalizacion.component.css']
})
export class EntregaHospitalizacionComponent extends SplusBaseForm implements OnInit, OnDestroy {
  @ViewChild("container", { read: ViewContainerRef })
  public containerRef: ViewContainerRef;
  filtersData = [
    {
      propertyName: "idCaracteristica",
      propertyValue: CaracteristicasModule.EntregaHospitalizacion,
    },
  ] as FiltersData[];
  closePop = false;
  DatosRecord: MovimientoInventarioDocumentosDTO;
  dataAdmisionTocontrol: AdmisionesDTO;
  usuariosEntrega: DefaultModelDTO[] = [];
  openedDiagAgregarSuministro = false;
  private unsubcribe$ = new Subject<void>();
  private service = inject(EntregaHospitalizacionService);
  private settings = inject(EntregaHospitalizacionSettings);
  private renderer = inject(Renderer2);
  private searchAdmissionsService = inject(SearchAdmissionsV2Service);
  private sPlusFormsDialogsService = inject(SPlusFormsDialogsService);

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/inventario/entrega-hospitalizacion/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearEntrega",
      showIn: ProcessType.view,
      text: "Crear nueva entrega",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarEntrega",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },

  ];


  // Grilla Medicamentos
  @ViewChild("gridMedicamentos") public gridRefMedicamentos: GridComponent;
  public gridDataMedicamentos: GridDataResult;
  dataGridMedicamentos: any[] = [];
  @ViewChild("gridMedicamentosSolicitud") public gridRefMedicamentosSolicitud: GridComponent;
  public gridDataMedicamentosSolicitud: GridDataResult;
  dataGridMedicamentosSolicitud: any[] = [];
  dataGridMedicamentosSolicitudSelected: any[] = [];
  dataGridMedicamentosSolicitudOriginal: any[] = [];

  editedRowIndexMedicamentos = 0;
  formGroupMedicamentos: FormGroup;
  isDeleteMedicamentos = false;
  saveEnterMedicamentos = false;
  public gridStateMedicamentos: State = {
    sort: [],
    skip: 0,
    take: 20,
  };
  private docClickSubscription: Subscription = new Subscription();
  usuariosFilter: DefaultModelDTO[];
  bodegas: DefaultModelDTO[];
  bodegasFilter: DefaultModelDTO[];
  lotesArticulos: LotesArticulosDTO[] = [];
  loteArticuloGrilla: LotesDTO[];

  mySelection: number[] = [];
  mySelectionSolicitudes: number[] = [];
  mySelectionKey(context: RowArgs): number {
    return context.index;
  }
  mySelectionKeySolicitudes(context: RowArgs): number {
    return context.index;
  }
  constructor() {
    super()
  }
  async ngOnInit() {

    this.id = this.route.snapshot.params["id"];
    this.title = this.route.snapshot.data["title"];
    this.icon = this.route.snapshot.data["icon"];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();
    this.isLoading = false;

    await this.ObtenerUsuarios();
    await this.ObtenerBodegas();
    this.docClickSubscription.add(
      this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    );

    if (this.id) {
      this.LoadRecord();
    }
  }

  ngOnDestroy(): void {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  handleFilter(value) {
    this.usuariosFilter = this.usuariosEntrega.filter(
      (s) => s.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }
  handleFilterBodegas(value) {
    this.bodegasFilter = this.bodegas.filter(
      (s) => s.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }
  async ObtenerUsuarios() {
    this.usuariosEntrega = await this.service.ObtenerUsuariosAsync<DefaultModelDTO[]>();
    this.usuariosFilter = this.usuariosEntrega;
  }
  async ObtenerBodegas() {
    this.bodegas = await this.service.ObtenerBodegasAsync<DefaultModelDTO[]>();
    this.bodegasFilter = this.bodegas;
  }
  /**
     * Abreviacion para acceder a los controles del FormRecord
     */
  public get f(): any {
    return this.FormRecord.controls;
  }
  async GetDataAdmision(data: AdmisionesDTO) {
    this.FormRecord.get("idAdmision").setValue(data.id);
    this.isLoading = true;
    if (ProcessType.create === this.processType) {

      this.dataGridMedicamentos = await this.service.ObtenerArticulosAsync<MovimientoInventarioDTO[]>(data.id);
      this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);


      this.dataGridMedicamentosSolicitud = JSON.parse(JSON.stringify(this.dataGridMedicamentos));
      this.dataGridMedicamentosSolicitudOriginal = JSON.parse(JSON.stringify(this.dataGridMedicamentosSolicitud));

      if (this.dataGridMedicamentos) {
        if (data.id && this.FormRecord.get("fkBodega").value) {
          this.ObtenerLotes(this.FormRecord.get("fkBodega").value, data.id)

        }

        this.isLoading = false;
      }

    } else {
      this.isLoading = false;
    }



  }
  async ObtenerLotes(idBodega, idAdmision) {
    this.isLoading = true;
    if (idAdmision && idBodega) {
      this.lotesArticulos = [];
      const ids = this.dataGridMedicamentos.map(s => s.articulo.id);
      this.lotesArticulos = await this.service.ObtenerLotesAsync<LotesArticulosDTO[]>(idBodega, ids);
      this.lotesArticulos = this.lotesArticulos.map(loteArticulo => ({
        ...loteArticulo,
        lotes: loteArticulo.lotes.filter(lote => lote.existencias && lote.existencias > 0)
      })).filter(loteArticulo => loteArticulo.lotes.length > 0);

      if (this.lotesArticulos) {
        this.dataGridMedicamentos = this.dataGridMedicamentos.map(item => ({
          ...item,
          lote: null,
          loteData: null,

        }));
        this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
        this.isLoading = false;
      }
    }
    else {
      this.isLoading = false;
    }

  }
  override onSubmit(event: any): void {
    try {
      event.preventDefault();
      this.submitted = true;
      if (this.FormRecord.valid) {
        if (this.FormRecord.pristine) return;
        this.isLoading = true;
        if (this.processType === this.ProcessType.edit) {
          this.UpdateRecord();
        } else {
          this.SaveRecord();
        }
      } else {
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
    }

  }
  ValidarDatos(): boolean {
    if (!this.dataGridMedicamentos || this.dataGridMedicamentos.length == 0) {
      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Error";
      dialogSettings.isHtml = true;
      dialogSettings.appendTo = this.containerRef;
      dialogSettings.description =
        `No se puede guardar la entrega por que no tiene suministros`;
      dialogSettings.actions = [{ text: "Cerrar" }];
      this.sPlusFormsDialogsService.Show(dialogSettings);
      return false;
    }
    const loteInvalid = this.dataGridMedicamentos.find(s => !s.loteData);
    if (loteInvalid) {
      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Error";
      dialogSettings.isHtml = true;
      dialogSettings.appendTo = this.containerRef;
      dialogSettings.description =
        `No se puede guardar la entrega por que el suministro <b>${loteInvalid?.articulo?.nombreCompletoCompuesto}</b>, no tiene un lote seleccionado`;
      dialogSettings.actions = [{ text: "Cerrar" }];
      this.sPlusFormsDialogsService.Show(dialogSettings);
      return false;
    }
    const sumaCantidadArray = this.dataGridMedicamentos.reduce((acc, item) => {
      acc[item.fkArticulo] = (acc[item.fkArticulo] || 0) + item.cantidad;
      return acc;
    }, {} as Record<number, number>);


    const validQuantity = this.dataGridMedicamentosSolicitudOriginal.filter(itemOriginal => {
      const cantidadUsada = sumaCantidadArray[itemOriginal.fkArticulo] || 0;
      return itemOriginal.cantidad < cantidadUsada;
    });
    if (validQuantity &&validQuantity.length > 0) {
      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Error";
      dialogSettings.appendTo = this.containerRef;
      dialogSettings.isHtml = true;
      dialogSettings.description =
        `No se puede guardar la entrega por que el suministro: <b>${validQuantity[0]?.articulo?.nombreCompletoCompuesto}</b>, por que supera la cantidad solicitada`;
      dialogSettings.actions = [{ text: "Cerrar" }];
      this.sPlusFormsDialogsService.Show(dialogSettings);
      return false;
    }
    const loteSinExistencia = this.dataGridMedicamentos.find(s => s.cantidad > s.loteData.existencias);
    if (loteSinExistencia) {
      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Error";
      dialogSettings.appendTo = this.containerRef;
      dialogSettings.isHtml = true;
      dialogSettings.description =
        `No se puede guardar la entrega por que el suministro: <b>${loteSinExistencia?.articulo?.nombreCompletoCompuesto}</b>, para el Lote: <b>${loteSinExistencia?.loteData?.nombreLote}</b> solo tiene ${loteSinExistencia.loteData?.existencias} en existencia`;
      dialogSettings.actions = [{ text: "Cerrar" }];
      this.sPlusFormsDialogsService.Show(dialogSettings);
      return false;
    }

    return true;
  }
  override SaveRecord(): void {
    if (!this.ValidarDatos()) {
      this.isLoading = false;
      return;
    }
    let data = this.FormRecord.value as MovimientoInventarioDocumentosDTO;
    data.hora = format(data.hora, 'HH:mm');

    data.movimientos = this.dataGridMedicamentos.map(s => {
      let map = {
        cantidad: s.cantidad,
        lote: s.loteData?.id,
        loteData: s.loteData,
        fkArticulo: s.articulo?.id,
        fechaVencimiento: s.loteData?.fechaVencimiento,
        valorArticulo: s.loteData?.valorUnitario,
        valorTotalArticulo: s.loteData?.valorUnitario * s.cantidad,
        fkLaboratorio: s.loteData.fkLaboratorio
      } as MovimientoInventarioDTO
      return map;
    });

    this.service
      .Guardar<number, MovimientoInventarioDocumentosDTO>(data)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }
  override UpdateRecord(): void {

    if (!this.ValidarDatos()) {
      this.isLoading = false;
      return;
    }
    let data = this.FormRecord.value as MovimientoInventarioDocumentosDTO;
    data.hora = format(data.hora, 'HH:mm');
    data.movimientos = this.dataGridMedicamentos.map(s => {
      let map = {
        id: s?.id,
        cantidad: s.cantidad,
        lote: s.loteData?.id,
        loteData: s.loteData,
        fkArticulo: s.articulo?.id,
        fechaVencimiento: s.loteData?.fechaVencimiento,
        valorArticulo: s.loteData?.valorUnitario,
        valorTotalArticulo: s.loteData?.valorUnitario * s.cantidad,
        fkLaboratorio: s.loteData?.fkLaboratorio
      } as MovimientoInventarioDTO
      return map;
    });
    data.id = this.id;
    this.service
      .Actualizar<boolean, MovimientoInventarioDocumentosDTO>(data)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([], {
              queryParams: { mode: "u" },
              queryParamsHandling: "merge",
            });
          }


        }
      });
  }
  override DeleteRecord(): void {
    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      idEntrega: this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        this.isLoading = false;
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }
  override LoadRecord(): void {
    this.isLoading = true;
    const userSearchParams = {
      idEntrega: this.id
    };
    this.service.Buscar<MovimientoInventarioDocumentosDTO>(userSearchParams).subscribe({
      next: async (res) => {
        this.isDeleting = false;
        if (res) {
          let admisiones = await this.searchAdmissionsService.FiltroAdmisionesAsync<AdmisionesDTO>(res.idAdmision);
          if (admisiones.isSuccessful) {
            this.FormRecord.get("admision").setValue(admisiones.result);
            this.dataAdmisionTocontrol = admisiones.result
          }

          this.CargarDatosOriginales(res);
          const articulosSolicitud = await this.service.ObtenerArticulosAsync<MovimientoInventarioDTO[]>(res.idAdmision);
          this.dataGridMedicamentosSolicitudOriginal = JSON.parse(JSON.stringify(articulosSolicitud));
          const sumaCantidadArray = this.dataGridMedicamentos.reduce((acc, item) => {
            acc[item.fkArticulo] = (acc[item.fkArticulo] || 0) + item.cantidad;
            return acc;
          }, {} as Record<number, number>);


          this.dataGridMedicamentosSolicitudOriginal = this.dataGridMedicamentosSolicitudOriginal.map(itemOriginal => {
            const cantidadUsada = sumaCantidadArray[itemOriginal.fkArticulo] || 0;
            if (cantidadUsada < itemOriginal.cantidad) {
              return {
                ...itemOriginal,
                cantidad: itemOriginal.cantidad + cantidadUsada
              };
            }

          });

          this.DatosRecord = JSON.parse(JSON.stringify(res));
          //this.DatosRecord = res
          this.isLoading = false;


        }
      }
    });
  }
  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar Entrega";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar esta Entrega?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }
  override goEdit(): void {
    this.routingState.goEdit();
  }
  override cancelEdit(): void {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }
  cancelUpdate() {
    this.DatosRecord.fecha = new Date(this.DatosRecord.fecha);
    this.DatosRecord.hora = new Date(this.DatosRecord.hora);
    this.CargarDatosOriginales(this.DatosRecord)

    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }
  override clearForm(): void {
    this.FormRecord = this.settings.FormComponents();
    this.FormRecord.updateValueAndValidity();
    this.formGroupMedicamentos = undefined;
    this.dataGridMedicamentos = [];
    this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);

    this.submitted = false;
  }

  isDate(value: any): boolean {
    return value instanceof Date && !isNaN(value.getTime());
  }
  CargarDatosOriginales(res: MovimientoInventarioDocumentosDTO) {
    if (!this.isDate(res.fecha)) {
      res.fecha = this.ToDate(res.fecha);
      if (res.hora)
        res.hora = this.SetHours(res.fecha, res.hora);
    }
    this.FormRecord.patchValue(res);
    this.dataGridMedicamentos = res.movimientos;
    this.ObtenerLotes(res.fkBodega, res.idAdmision);
    this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
    //this.DatosRecord = res;
  }
  //#region Grilla Medicamentos

  private closeEditorMedicamentos(
    rowIndex: number = this.editedRowIndexMedicamentos
  ): void {
    if (!this.gridRefMedicamentos) return;
    if (this.gridRefMedicamentos) this.gridRefMedicamentos.closeRow(rowIndex);
    this.editedRowIndexMedicamentos = undefined;
    this.formGroupMedicamentos = undefined;
  }
  public cellClickHandlerMedicamentos(args: CellClickEvent): void {
    if (args.isEdited) return;
    if (this.processType == ProcessType.view) return;
    if (this.formGroupMedicamentos && this.formGroupMedicamentos.valid) this.saveCurrentMedicamentos();
    if (!this.gridRefMedicamentos) return;
    if (this.isDeleteMedicamentos) {
      this.isDeleteMedicamentos = false;
      return;
    }

    if (this.formGroupMedicamentos && !this.formGroupMedicamentos.valid && args.isEdited) {
      return;
    }
    if (this.formGroupMedicamentos && this.formGroupMedicamentos.valid && this.saveEnterMedicamentos) {
      this.saveCurrentMedicamentos();
    }
    if (!args.isEdited) {
      this.closeEditorMedicamentos();
      this.editedRowIndexMedicamentos = args.rowIndex;
      this.createFormGroupMedicamentos(args.dataItem);
      this.gridRefMedicamentos.editCell(args.rowIndex, args.columnIndex, this.formGroupMedicamentos);
      this.editedRowIndexMedicamentos = args.rowIndex;

    }
  }
  public saveCurrentMedicamentos(): void {
    if (!this.formGroupMedicamentos || (this.formGroupMedicamentos && !this.formGroupMedicamentos.valid)) {
      return;
    }
    this.saveMedicamentos(this.formGroupMedicamentos.getRawValue());
  }

  public saveMedicamentos(product: any): void {
    if (product?.articulo) {
      this.FormRecord.markAsDirty();
      this.FormRecord.updateValueAndValidity();
      this.formGroupMedicamentos.markAsDirty();
      this.formGroupMedicamentos.updateValueAndValidity();
      const data = this.dataGridMedicamentos[this.editedRowIndexMedicamentos];

      Object.assign(data, product);
      this.closeEditorMedicamentos();
    }

  }
  ValidarExitencia(lote: LotesDTO, index: number) {
    let data = this.dataGridMedicamentos[index];
    data.loteData = lote;
    if (lote && lote.existencias /*< data.cantidadPermitida*/) {
      data.cantidadPermitida = lote.existencias
    }

  }
  public createFormGroupMedicamentos(dataItem: any) {
    if (dataItem.articulo?.id) {
      this.loteArticuloGrilla = [];
      const dataLote = this.lotesArticulos.find(s => s.idArticulo == dataItem.articulo.id);
      this.loteArticuloGrilla = dataLote ? dataLote.lotes : [];
      if (this.loteArticuloGrilla.length == 0) {
        dataItem.cantidadPermitida = 0;
      }
      else{
        const indices = this.dataGridMedicamentos.reduce((acc, item, index) => {
          if (item.fkArticulo === dataItem.articulo.id) {
            acc.push(index);
          }
          return acc;
        }, [] as number[]);
        indices.forEach(element => {
          if(element != this.editedRowIndexMedicamentos && this.dataGridMedicamentos[element].loteData) {
            this.loteArticuloGrilla = this.loteArticuloGrilla.filter(lote => lote.id != this.dataGridMedicamentos[element].loteData.id);
          }


        });
      }

    }

    this.formGroupMedicamentos = new FormGroup({
      id: new FormControl(dataItem.id ? dataItem.id : 0),
      articulo: new FormControl(dataItem.articulo ? dataItem.articulo : null),
      cantidad: new FormControl(dataItem.cantidad ? dataItem.cantidad : null, [Validators.required, Validators.max(dataItem.cantidadPermitida), Validators.min(1)]),
      loteData: new FormControl(dataItem.loteData ? dataItem.loteData : null, [Validators.required]),
    });
  }
  public removeHandlerMedicamento(args: any): void {
    this.FormRecord.markAsDirty();
    this.FormRecord.updateValueAndValidity();
    this.isDeleteMedicamentos = true;
    this.editedRowIndexMedicamentos = args.rowIndex;
    this.removeMedicamentos();

  }
  public removeMedicamentos(): void {
    this.dataGridMedicamentos.splice(this.editedRowIndexMedicamentos, 1);
    this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
    this.closeEditorMedicamentos();
  }
  public cancelHandlerMedicamentos(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditorMedicamentos(args.rowIndex);
  }

  pageChangeMedicamentos(data: State) {
    this.gridStateMedicamentos = data;
    this.gridDataMedicamentos = process(this?.dataGridMedicamentos, this.gridStateMedicamentos);
  }
  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }
  private onDocumentClick(e: Event): void {

    if (!this.gridRefMedicamentos) return;
    if (!this.matches(e.target, "#gridMedicamentos tbody *, #gridMedicamentos .k-table-td *, .info")) {
      this.saveCurrentMedicamentos();
    }

  }



  //#endregion

  dialogEliminarConceptosAplicadosSelecionados() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Remover Conceptos Seleccionados";
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.description =
      "¿Estás seguro que desea remover estos articulos de la entrega?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Sí, Remover", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
        } else {
          if (result.value) {
            this.dataGridMedicamentos = this.dataGridMedicamentos.filter((_, index) => !this.mySelection.includes(index));
            this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
            this.closePop = true;
            this.mySelection = [];
            setTimeout(() => {
              this.closePop = false;
            }, 500)
          }
        }
      });
  }
  GetSelectionEmpleado(empleados: MovimientoInventarioDTO[] | MovimientoInventarioDTO) {
    this.FormRecord.markAsDirty();

    if (Array.isArray(empleados)) {
      this.mySelectionSolicitudes = [];
      this.dataGridMedicamentos = [...this.dataGridMedicamentos, ...empleados];
    }
    this.openedDiagAgregarSuministro = false;
  }
  openedAgregarSuministro() {
    this.openedDiagAgregarSuministro = true;
    const sumaCantidadArray = this.dataGridMedicamentos.reduce<Record<number, number>>((acc, item) => {
      if (typeof item.fkArticulo === 'number' && typeof item.cantidad === 'number') {
          acc[item.fkArticulo] = (acc[item.fkArticulo] || 0) + item.cantidad;
      }
      return acc;
  }, {});


  this.dataGridMedicamentosSolicitud = this.dataGridMedicamentosSolicitudOriginal
  .map(itemOriginal => {
      if (typeof itemOriginal.fkArticulo === 'undefined') {
          return null;
      }

      const cantidadUsada = sumaCantidadArray[itemOriginal.fkArticulo] || 0;
      const cantidadRestante = itemOriginal.cantidad - cantidadUsada;

      if (cantidadRestante > 0) {
          return {
              ...itemOriginal,
              cantidad: cantidadRestante
          };
      }
      return null;
  })
  .filter((item): item is NonNullable<typeof item> => item !== null);



  }
  public closeSuministro(): void {
    this.openedDiagAgregarSuministro = false;
  }
  toggleSelectAll() {
    this.mySelectionSolicitudes = this.dataGridMedicamentosSolicitud.map(s => s.fkArticulo);
  }
  selecctionChage(dataSelect: number[]) {
    this.dataGridMedicamentosSolicitudSelected = this.dataGridMedicamentosSolicitud.filter(empleado =>
      dataSelect.includes(empleado.fkArticulo)
    );

  }
  callLoadSelect() {
    if (this.dataGridMedicamentosSolicitudSelected) {
      this.dataGridMedicamentos = [...this.dataGridMedicamentos, ...this.dataGridMedicamentosSolicitudSelected];
      this.gridDataMedicamentos = process(this.dataGridMedicamentos, this.gridStateMedicamentos);
    }
    this.mySelectionSolicitudes = [];
    this.openedDiagAgregarSuministro = false;

  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component";
import { EntregaHospitalizacionComponent } from "./components/entrega-hospitalizacion.component";
import { EntregaHospitalizacionSettings } from "./config/entrega-hospitalizacion.config";
import { EntregaHospitalizacionSearch } from "./config/entrega-hospitalizacion.search";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Entrega Hospitalización Listado" },
  },
  { path: "", component: EntregaHospitalizacionComponent },
  { path: ":id", component: EntregaHospitalizacionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntregaHospitalizacionRoutingModule {

  //Routing principal
  public static RoutingEntregaHospitalizacion = {
    path: "inventario/entrega-hospitalizacion",
    loadChildren: () =>
      import("./entrega-hospitalizacion.module").then(
        (m) => m.EntregaHospitalizacionModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Entrega Hospitalización`,
      icon: "fa fa-folder-open",
      setting: EntregaHospitalizacionSettings,
      search : EntregaHospitalizacionSearch
    },
  };
}

import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";

export class EntregaHospitalizacionSettings {


  constructor() {}


  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {
        idAdmision: new TegettFormControl({
          formState: null,
          validatorOrOpts: [Validators.required],
          label: "Admisión",
          typeField: TypeField.List,
          required: true,
          nameControl: "idAdmision",
        }),
        admision: new TegettFormControl({
          formState: null,
          label: "Admisión",
          typeField: TypeField.List,
          required: true,
          nameControl: "admision",
        }),
        fecha: new TegettFormControl({
          formState: new Date(),
          validatorOrOpts: [Validators.required],
          label: "Fecha",
          typeField: TypeField.Date,
          required: true,
          nameControl: "fecha",
        }),
        hora: new TegettFormControl({
          formState: new Date(),
          validatorOrOpts: [Validators.required],
          label: "Hora",
          typeField: TypeField.Date,
          required: true,
          nameControl: "fecha",
        }),
        idUsuarioEntrega: new TegettFormControl({
          formState: null,
          validatorOrOpts: [Validators.required],
          label: "Entregado A:",
          typeField: TypeField.List,
          required: true,
          nameControl: "idUsuarioEntrega",
        }),
        fkBodega: new TegettFormControl({
          formState: null,
          validatorOrOpts: [Validators.required],
          label: "Bodegas",
          typeField: TypeField.List,
          required: true,
          nameControl: "fkBodega",
        })

      }
    );

    return formRecord;
  }




}

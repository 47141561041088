import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { SplusBaseService } from '@src/app/services/Generals/splusBase.service';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntregaHospitalizacionService extends SplusBaseService {
  static instance: EntregaHospitalizacionService;
  error: HttpErrorResponse;

  constructor() {
    super();
    EntregaHospitalizacionService.instance = this;
    this.nameEndPoint = `${ControllerApiList.Inventario.EntregaHospitalizacion}`
  }


  async ObtenerUsuariosAsync<T>(): Promise<T> {

    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ObtenerUsuarios`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    const dataResponse = await lastValueFrom(record);
    return dataResponse;
  }
  async ObtenerBodegasAsync<T>(): Promise<T> {

    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ObtenerBodegas`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    const dataResponse = await lastValueFrom(record);
    return dataResponse;
  }
  async ObtenerArticulosAsync<T>(idAdmision:number): Promise<T> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("idAdmision", idAdmision);
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ArticulosSolicitudes`,{params:httpParams}
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    const dataResponse = await lastValueFrom(record);
    return dataResponse;
  }
  async ObtenerLotesAsync<T>(idBodega:number, idsArticulos:number[]): Promise<T> {

    const httpParams = new HttpParams({ fromObject: { idBodega, idsArticulos} });
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ObtenerLotes`,{params:httpParams}
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    const dataResponse = await lastValueFrom(record);
    return dataResponse;
  }

}



import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, inject } from "@angular/core";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  DateFilter,
  MultipleFilter,
} from "@src/app/models/Tegett/SimpleFilterData";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods } from "@src/app/models/base/filterModel";
import { EntregaHospitalizacionSettings } from "./entrega-hospitalizacion.config";

@Component({
  selector: "app-entregaHospitalizacionSettings",
  template: "",
})
export class EntregaHospitalizacionSearch implements BaseSearchSetting {

  tiposDocumentos: MultipleFilter;
  selectionPopup = false;


  private router = inject(Router)
  private route = inject(ActivatedRoute)
  private settings = inject(EntregaHospitalizacionSettings)

  constructor() { }
  async Load() {
    return true;


  }

  /**
   * Funcion para armar la busqueda
   */
  loadFormSearch(): FormSearch {
    const solicitudSearch = new FormSearch();

    solicitudSearch.formComponents = this.settings.FormComponents()
    solicitudSearch.configSearch = {
      title: "Entrega Hospitalizacion",
      titleIcon: "fa fa-folder-open",
      apiSearch: ControllerApiList.Inventario.EntregaHospitalizacion,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nueva Entrega",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [
        { objectKey: "id", sortV3: "desc", visible: false },
      ],
      fields: [
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },
        {
          renderLabel: "Paciente",
          objectKey: "paciente",
          //module: "empleados",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          selectionPopup: this.selectionPopup,
        },

        {
          objectKey: "numeroAdmision",
          //pipe: "date",
          renderLabel: "Numero Admisión",
        },
        {
          objectKey: "fecha",
          //pipe: "date",
          renderLabel: "Fecha",
        },
        {
          objectKey: "hora",
          //pipe: "date",
          renderLabel: "Hora",
        },
      ],
      filters: [
        { objectKey: "nombre1Paciente", type: SearchTypeFilter.Text },
        { objectKey: "nombre2Paciente", type: SearchTypeFilter.Text },
        { objectKey: "apellido1Paciente", type: SearchTypeFilter.Text },
        { objectKey: "apellido2Paciente", type: SearchTypeFilter.Text },
        {
          objectKey: "fecha",
          type: SearchTypeFilter.DateFilter,
          data: this.ConfigDate()
        },

      ],
    };
    return solicitudSearch;
  }




  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }
  ConfigDate() {
    let filter = {} as DateFilter;
    filter.label = "Fecha";
    filter.name = "fecha";
    filter.type = SearchTypeFilter.DateFilter;
    return filter;
  }
  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
